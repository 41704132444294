<template>
  <header role="banner">
    <div class="header-large">
      <!-- <div class="container-fluid py-5"> -->
      <div id="quicklinks-desktop">
        <b-form-checkbox v-model="$store.state.translateJP" name="check-button" switch
          style="padding-left: 55px; padding-bottom: 20px;">
          Japanese
        </b-form-checkbox>
        <p class="quicklinks-title" v-if="!$store.state.translateJP">Quick Links</p>
        <p class="quicklinks-title" v-else>クイックリンク</p>
        <ul>
          <li>
            <a href="https://confluence.internal.salesforce.com/display/SECURITY/Report+Suspicious+Activity"
              target="_blank">
              <span v-if="!$store.state.translateJP">Report Suspicious Activity</span>
              <span v-else>疑念のあるアクティビティの報告</span>
            </a>
          </li>
          <li>
            <a href="https://btegrc.my.salesforce.com/?ec=302&startURL=%2Fvisualforce%2Fsession%3Furl%3Dhttps%253A%252F%252Fbtegrc.lightning.force.com%252Flightning%252Fo%252FUserAppMenuItem%252Fhome"
              target="_blank">
              <span v-if="!$store.state.translateJP">Policies/Standards (eGRC360)</span>
              <span v-else>ポリシー/スタンダード (eGRC360)</span>
            </a>
          </li>
          <li>
            <a href="https://salesforce-internal.slack.com/canvas/C01TRTEK4FM?focus_section_id=temp:C:fDB714ec6d64dee4864bca8248b1"
              target="_blank">
              <span v-if="!$store.state.translateJP">Security Tips</span>
              <span v-else>セキュリティ情報</span>
            </a>
          </li>
          <li>
            <a href="https://globalsafetyandsecurity.salesforce.com" target="_blank">
              <span v-if="!$store.state.translateJP">Global Safety & Security</span>
              <span v-else>Global Safety & Security</span>
            </a>
          </li>
          <li>
            <a href="https://confluence.internal.salesforce.com/pages/viewpage.action?pageId=458635552" target="_blank">
              <span v-if="!$store.state.translateJP">Customer Security/DSRs</span>
              <span v-else>Customer Security/DSRs</span>
            </a>
          </li>
          <li>
            <a href="http://sfdc.co/SA-Transformation-Guidlines"
              target="_blank">
              <span v-if="!$store.state.translateJP">Security Assessments/SSDL</span>
              <span v-else>セキュリティアセスメント/SSDL</span>
            </a>
          </li>
          <li>
            <a href="https://www.salesforce.com/products/platform/solutions/data-security/" target="_blank">
              <span v-if="!$store.state.translateJP">Security Products</span>
              <span v-else>セキュリティ製品</span>
            </a>
          </li>
          <li>
            <a href="https://concierge.it.salesforce.com/articles/en_US/Supportforce_Article/Security-Trainings-and-Helpful-Links"
              target="_blank">
              <span v-if="!$store.state.translateJP">Security Trainings and Helpful Links</span>
              <span v-else>セキュリティ研修/お役立ちリンク</span>
            </a>
          </li>
        </ul>
      </div>
      <div id="quicklinks-mobile">
        <b-dropdown id="dropdown-1" text="Quicklinks" class="">
          <b-form-checkbox v-model="$store.state.translateJP" name="check-button" switch
            style="padding-left: 55px; padding-bottom: 10px;">
            Japanese
          </b-form-checkbox>
          <b-dropdown-item href="https://confluence.internal.salesforce.com/display/SECURITY/Report+Suspicious+Activity"
            target="_blank">
            <span v-if="!$store.state.translateJP">Report Suspicious Activity</span>
            <span v-else>疑念のあるアクティビティの報告</span>
          </b-dropdown-item>
          <b-dropdown-item
            href="https://btegrc.my.salesforce.com/?ec=302&startURL=%2Fvisualforce%2Fsession%3Furl%3Dhttps%253A%252F%252Fbtegrc.lightning.force.com%252Flightning%252Fo%252FUserAppMenuItem%252Fhome"
            target="_blank">
            <span v-if="!$store.state.translateJP">Policies/Standards (eGRC360)</span>
            <span v-else>ポリシー/スタンダード (eGRC360)</span>
          </b-dropdown-item>
          <b-dropdown-item
            href="https://salesforce-internal.slack.com/canvas/C01TRTEK4FM?focus_section_id=temp:C:fDB714ec6d64dee4864bca8248b1"
            target="_blank">
            <span v-if="!$store.state.translateJP">Security Tips</span>
            <span v-else>セキュリティ情報</span>
          </b-dropdown-item>
          <b-dropdown-item href="https://globalsafetyandsecurity.salesforce.com" target="_blank">
            <span v-if="!$store.state.translateJP">Global Safety & Security</span>
            <span v-else>Global Safety & Security</span>
          </b-dropdown-item>
          <b-dropdown-item href="https://confluence.internal.salesforce.com/pages/viewpage.action?pageId=458635552"
            target="_blank">
            <span v-if="!$store.state.translateJP">Customer Security/DSRs</span>
            <span v-else>Customer Security/DSRs</span>
          </b-dropdown-item>
          <b-dropdown-item
            href="http://sfdc.co/SA-Transformation-Guidlines"
            target="_blank">
            <span v-if="!$store.state.translateJP">Security Assessments/SSDL</span>
            <span v-else>セキュリティアセスメント/SSDL</span>
          </b-dropdown-item>
          <b-dropdown-item href="https://www.salesforce.com/products/platform/solutions/data-security/" target="_blank">
            <span v-if="!$store.state.translateJP">Security Products</span>
            <span v-else>セキュリティ製品</span>
          </b-dropdown-item>
          <b-dropdown-item
            href="https://concierge.it.salesforce.com/articles/en_US/Supportforce_Article/Security-Trainings-and-Helpful-Links"
            target="_blank">
            <span v-if="!$store.state.translateJP">Security Trainings and Helpful Links</span>
            <span v-else>セキュリティ研修/お役立ちリンク</span>
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="header_text_desktop" v-if="!$store.state.translateJP">Trust is our #1 Value</div>
      <div class="header_text_desktop" v-else>トラストは私たちの最優先価値です</div>
      <!-- <img
        class="header_text_desktop img-fluid"
        src="../assets/images/gss_heading.svg"
        alt=""
      /> -->
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      checked: false
    }
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped></style>
