<template>
  <div class="home">
    <div id="about">
      <div class="container pt-4 py-lg-5 text-center">
        <p class="text-large mx-auto" v-if="!$store.state.translateJP">
          At Salesforce, Trust is our number one value, and Trust and Security go hand-in-hand. We take the protection of our customer data and company information very seriously. As cybersecurity threats continue to grow in frequency and evolve in sophistication, we must approach security as a team sport, following proper preventative protocols and remaining vigilant of threats that could potentially impact you, and Salesforce, and our customers.
        </p>
        <p class="text-large mx-auto" v-else>
          セールスフォースでは、「信頼」を第一の価値観としており、「信頼」と「セキュリティ」は密接に関係しています。私たちは、お客様のデータと企業情報の保護を非常に重要視しています。サイバーセキュリティの脅威が頻度を増し、高度に進化し続ける中、私たちはチームスポーツとしてセキュリティに取り組み、適切な予防手順に従い、お客様、そしてセールスフォースやお客様に影響を与える可能性のある脅威に対して警戒を怠らないようにしなければなりません。
        </p>

        <div class="row pt-3 py-lg-4">
          <div class="col-md-4 px-5 about contact-center">
            <img
              class="py-4 gss_icon"
              src="../assets/images/cyber/contact.webp"
              alt=""
            />
            <h3 class="text-uppercase py-3 font-weight-bolder" v-if="!$store.state.translateJP">
              Contact Security 24/7
            </h3>
            <h3 class="text-uppercase py-3 font-weight-bolder" v-else>
              セキュリティ受付（年中無休）
            </h3>
            <p class="px-2">
              Email <a href="mailto:security@salesforce.com">security@salesforce.com</a><br>
              Call <a href="tel:14155365230">+1 415-536-5230</a><br>
              Slack <a href="https://salesforce-internal.slack.com/archives/C02RDS0USER" target="_blank">#help-security</a>
            </p>
            <!-- <b-button size="lg" class="mobile-btn">Learn More</b-button> -->
          </div>
          <div class="col-md-4 px-5 about trailhead">
            <img
              class="py-4 gss_icon"
              src="../assets/images/cyber/training.webp"
              alt=""
            />
            <h3 class="text-uppercase py-3 font-weight-bolder" v-if="!$store.state.translateJP">
              Security Training
            </h3>
            <h3 class="text-uppercase py-3 font-weight-bolder" v-else>
              セキュリティトレーニング
            </h3>
            <p class="px-2" v-if="!$store.state.translateJP">
              Visit Concierge for <a href="https://concierge.it.salesforce.com/articles/en_US/Supportforce_Article/Security-Trainings-and-Helpful-Links" target="_blank">security trainings and helpful links</a>
            </p>
            <p class="px-2" v-else>
              <a href="https://concierge.it.salesforce.com/articles/en_US/Supportforce_Article/Security-Trainings-and-Helpful-Links" target="_blank">セキュリティ研修や関連情報のリンクはコンシェルジュを利用ください</a>
            </p>
          </div>
          <div class="col-md-4 px-5 about email">
            <img
              class="py-4 gss_icon"
              src="../assets/images/cyber/updates.webp"
              alt=""
            />
            <h3 class="text-uppercase py-3 font-weight-bolder" v-if="!$store.state.translateJP">
              Security Updates
            </h3>
            <h3 class="text-uppercase py-3 font-weight-bolder" v-else>
              セキュリティアップデート
            </h3>
            <p class="px-2" v-if="!$store.state.translateJP">
              Join the <a href="https://salesforce-internal.slack.com/archives/C01TRTEK4FM" target="_blank">#broadcast-security</a> Slack channel
            </p>
            <p class="px-2" v-else>
              スラックチャネル <a href="https://salesforce-internal.slack.com/archives/C01TRTEK4FM" target="_blank">#broadcast-security</a> に参加ください
            </p>
          </div>
        </div>
      </div>
    </div>

    <div id="security-six">
      <div class="container py-lg-4 text-center">
        <h2>The Security Six</h2>
        <p class="text-large mx-auto pt-4" v-if="!$store.state.translateJP">
          When it comes to security, the focus is on mindfulness - slowing down and being intentional in our actions. Be on the lookout for the top six security threats.
        </p>
        <p class="text-large mx-auto pt-4" v-else>
          セキュリティに関しては、マインドフルネス、つまり落ち着いて、意図的に行動することが重視されます。セキュリティの脅威のトップ6に気をつけましょう。
        </p>
      </div>
    </div>

    <div id="cloudBg">
      <div class="container py-lg-2 text-center">

        <div class="six-cards row">
          <div class="col-12 col-md-6 col-lg-4">
            <div class="card">
              <div class="card-header">
                <div class="icon">
                  <img src="../assets/images/cyber/social_engineering.webp" alt="">
                </div>
              </div>
              <h3 class="card-title">
                <a href="https://salesforce.quip.com/nkLXAcU5xxX2#temp:C:ZSD41b58b6267044cb88539848d9" target="_blank">
                  <span v-if="!$store.state.translateJP">Recognize Social Engineering</span>
                  <span v-else>ソーシャルエンジニアリングを認識する</span>
                </a>
              </h3>
              <p class="card-body" v-if="!$store.state.translateJP">Be aware of social engineering tactics and don’t fall victim to attempts via email, phone, and social media to elicit personal and confidential information. It’s important to remain vigilant and aware of suspicious activity.</p>
              <p class="card-body text-left" v-else>ソーシャル・エンジニアリングの手口に注意し、電子メール、電話、ソーシャルメディアを通じて個人情報や機密情報を聞き出そうとする企てに巻き込まれないようにしましょう。不審な動きには常に警戒し、注意を払うことが大切です。</p>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div class="card">
              <div class="card-header">
                <div class="icon">
                  <img style="width:55px; padding-left: 0; height: auto" src="../assets/images/cyber/devices.webp" alt="">
                </div>
              </div>
              <h3 class="card-title">
                <a href="https://salesforce.quip.com/nkLXAcU5xxX2#temp:C:ZSDc8edf5301e924260b9ccc9b5a" target="_blank">
                  <span v-if="!$store.state.translateJP">Secure Your Devices</span>
                  <span v-else>デバイスを安全に保つ</span>
                </a>
              </h3>
              <p class="card-body" v-if="!$store.state.translateJP">Only use approved software and services. Do not connect devices that aren’t owned by Salesforce to the corporate network with a network cable or through the corporate wireless network.</p>
              <p class="card-body text-left" v-else>承認されたソフトウェアとサービスのみを使用すること。Salesforce が所有していないデバイスをネットワークケーブルで企業ネットワークに接続したり、企業無線ネットワークを通じて接続したりしないでください。</p>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div class="card">
              <div class="card-header">
                <div class="icon">
                  <img style="width: 90px; padding-left: 0; margin-top: 30px;" src="../assets/images/cyber/secrets.webp" alt="">
                </div>
              </div>
              <h3 class="card-title">
                <a href="https://salesforce.quip.com/nkLXAcU5xxX2#temp:C:ZSD1757af106b944970b69c01f2b" target="_blank">
                  <span v-if="!$store.state.translateJP">Keep Secrets Secret</span>
                  <span v-else>パスワードを他人に教えない</span>
                </a>
              </h3>
              <p class="card-body" v-if="!$store.state.translateJP">Keeping track of your passwords is critical to maintaining the safety of our networks, our data, and our customers’ trust. Use a password manager, like LastPass, and never reuse passwords.</p>
              <p class="card-body text-left" v-else>パスワードの管理は、ネットワークの安全性、データの安全性、そしてお客様の信頼性を維持するために非常に重要です。LastPassのようなパスワードマネージャーを使用し、決してパスワードを再利用しないようにしましょう。</p>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div class="card">
              <div class="card-header">
                <div class="icon">
                  <img style="width: 90px; padding-left: 0; margin-top: 20px;" src="../assets/images/cyber/data.webp" alt="">
                </div>
              </div>
              <h3 class="card-title">
                <a href="https://salesforce.quip.com/nkLXAcU5xxX2#temp:C:ZSD8827897be84a45fdbeb087138" target="_blank">
                  <span v-if="!$store.state.translateJP">Handle Data with Care</span>
                  <span v-else>データの取扱</span>
                </a>
              </h3>
              <p class="card-body" v-if="!$store.state.translateJP">Share, store, and retain data carefully. If you inadvertently expose data to the wrong customer, employee or any person for that matter, immediately contact <a href="mailto:security@salesforce.com">security@salesforce.com</a>.</p>
              <p class="card-body text-left" v-else>データの共有、保存、保持は慎重に行うこと。不注意で間違ったお客様、従業員、あるいはそれ以外の人にデータを公開した場合は、すぐに <a href="mailto:security@salesforce.com">security@salesforce.com</a> に連絡してください。</p>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div class="card">
              <div class="card-header">
                <div class="icon">
                  <img style="width: 90px; padding-left: 0; margin-top: 20px;" src="../assets/images/cyber/surroundings.webp" alt="">
                </div>
              </div>
              <h3 class="card-title">
                <a href="https://salesforce.quip.com/nkLXAcU5xxX2#temp:C:ZSD1e350a9210154c75a7f041cd5" target="_blank">
                  <span v-if="!$store.state.translateJP">Be Aware of Your Surroundings</span>
                  <span v-else>周囲に気を配る</span>
                </a>
              </h3>
              <p class="card-body" v-if="!$store.state.translateJP">Whenever you connect to the Salesforce network, make sure you utilize the Salesforce VPN and Single Sign On portal to log into applications. Lock your computer screen and devices when you're not using them.</p>
              <p class="card-body text-left" v-else>Salesforce のネットワークに接続するときは、必ず Salesforce VPN と Single Sign On ポータルを利用してアプリケーションにログインしてください。コンピュータを使用していない時には、コンピュータの画面やデバイスをロックしてください。</p>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div class="card">
              <div class="card-header">
                <div class="icon">
                  <img style="width: 90px; padding-left: 0; margin-top: 20px;" src="../assets/images/cyber/see_something.webp" alt="">
                </div>
              </div>
              <h3 class="card-title">
                <a href="https://salesforce.quip.com/nkLXAcU5xxX2#temp:C:ZSD627fe44aa2f44be9bdb04e954" target="_blank">
                  <span v-if="!$store.state.translateJP">See Something Say Something</span>
                  <span v-else>何かを見たらすぐ報告</span>
                </a>
              </h3>
              <p class="card-body" v-if="!$store.state.translateJP">Employees are our first line of defense when it comes to protecting Salesforce. Please report anything suspicious to <a href="mailto:security@salesforce.com">security@salesforce.com</a>.</p>
              <p class="card-body text-left" v-else>Salesforceを守るためには、従業員が第一の防衛線となります。不審な点があれば、<a href="mailto:security@salesforce.com">security@salesforce.com</a> までご連絡ください。</p>
            </div>
          </div>
        </div>

      </div>


      <div class="container py-lg-2 text-center">
        <h2 class="pb-5" v-if="!$store.state.translateJP">Check out the security trainings most important to you.</h2>
        <h2 class="pb-5" v-else>重要なセキュリティトレーニングをチェックする</h2>

        <div id="trainings">
          <div class="col-12 col-md-4 col-lg-4">
            <div class="trainingCard">
              <img style="" src="../assets/images/cyber/sales.webp" alt="">
                <h3 v-if="!$store.state.translateJP">Sales</h3>
                <h3 v-else>営業むけ</h3>
                <p v-if="!$store.state.translateJP">Today, customers are more concerned than ever about the security of their data. When we win their trust, we win deals. So take a security-first approach to your customer engagements.</p>
                <p v-else>今日、お客様はデータの安全性についてこれまで以上に関心を寄せています。彼らの信頼を勝ち取ることで、取引を獲得することができます。そのために、「セキュリティ ファースト」のアプローチでお客様との関係性を築いてください。</p>
              <a href="https://salesforce.highspot.com/spots/610aa28db1710c546341acae" target="_blank" class="btn btn-primary">
                <span v-if="!$store.state.translateJP">Learn More</span>
                <span v-else>詳細はこちら</span>
              </a>
            </div>
          </div>
          <div class="col-12 col-md-4 col-lg-4">
            <div class="trainingCard">
              <img style="margin-top: -15px;" src="../assets/images/cyber/engineers.webp" alt="">
                <h3 v-if="!$store.state.translateJP">Engineers</h3>
                <h3 v-else>エンジニア向け</h3>
                <p v-if="!$store.state.translateJP">Our engineers and developers build and manage hundreds of products and services here at Salesforce. It’s critical that you protect the assets our customers have entrusted to us. Get familiar with the OWASP Top 10 and other security best practices.</p>
                <p v-else>Salesforce では、エンジニアや開発者が何百もの製品やサービスを構築し、管理しています。お客様が私たちに託した資産を保護することは非常に重要です。OWASP Top 10 やその他のセキュリティベストプラクティスを習得してください。</p>
              <a href="https://org62.my.trailhead.com/content/employee/trails/develop-securely-at-salesforce-2023" target="_blank" class="btn btn-primary">
                <span v-if="!$store.state.translateJP">Learn More</span>
                <span v-else>詳細はこちら</span>
              </a>
            </div>
          </div>
          <div class="col-12 col-md-4 col-lg-4">
            <div class="trainingCard">
              <img style="" src="../assets/images/cyber/executives.webp" alt="">
                <h3 v-if="!$store.state.translateJP">Executives</h3>
                <h3 v-else>エグゼクティブ向け</h3>
                <p v-if="!$store.state.translateJP">The Executive Security Guide highlights many of today’s top security threats and the steps you can take both at work and at home to help protect yourself, your family, and the company.</p>
                <p v-else>エグゼクティブ・セキュリティ・ガイドは、今日のセキュリティ上の脅威の多くを取り上げ、自分自身、家族、そして会社を守るために、職場と家庭でできることを紹介しています。</p>
              <a href="https://salesforce.quip.com/vaH3AXTKAbiV" target="_blank" class="btn btn-primary">
                <span v-if="!$store.state.translateJP">Learn More</span>
                <span v-else>詳細はこちら</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <img style="width: 100%;" src="../assets/images/cyber/footer.webp" alt="">

    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import store from "@/store";

export default {
  name: "Home",
  components: {
    // HelloWorld
  },
  data() {
    return {
      reload: false
    };
  },
  created() {
    // console.log(this.$cookies.get('saml_auth'));
    // console.log(this.$cookies.get('saml_auth') == 1);
    // if (this.$cookies.get('saml_auth') != 1 && this.reload == false) {
    //   console.log("reload")
    //   location.reload();
    //   this.reload = true
    // }
  },
};
</script>

<style lang="scss">
</style>
