<template>
  <footer role="contentinfo">
    <div class="footer-top">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-12">
            <img
              class="img-fluid px-lg-5 footer-logo"
              src="../assets/images/cyber/security-logo.webp"
              alt="Security Logo"
            />
          </div>
          <div class="col-lg-4 col-6 py-5 py-lg-0">
            <b-nav vertical class="footerNav">
              <b-nav-item href="https://concierge.it.salesforce.com/articles/en_US/Supportforce_Article/Security-Top-of-Mind" target="_blank">Security Top of Mind</b-nav-item>
              <b-nav-item href="https://salesforce-internal.slack.com/archives/C01TRTEK4FM" target="_blank">
                <span v-if="!$store.state.translateJP">Security Updates</span>
                <span v-else>セキュリティアップデート</span>
              </b-nav-item>
              <b-nav-item href="https://concierge.it.salesforce.com/articles/en_US/Supportforce_Article/Security-Trainings-and-Helpful-Links" target="_blank">
                <span v-if="!$store.state.translateJP">Security Trainings and Helpful Links</span>
                <span v-else>セキュリティトレーニングと参考情報</span>
              </b-nav-item>
            </b-nav>
          </div>
          <div class="col-lg-4 pt-3 pt-lg-0">
            <p class="footer-title" v-if="!$store.state.translateJP">Need to report a security issue or incident?</p>
            <p class="footer-title" v-else>セキュリティの問題やインシデントの報告先</p>
            <b-nav vertical class="footerNav">
              <b-nav-item href="mailto:security@salesforce.com"><img src="../assets/images/cyber/icon-envelope.png" />security@salesforce.com</b-nav-item>
              <b-nav-item href="tel:14155365230"><img src="../assets/images/cyber/icon-phone.png" />+1 415-536-5230</b-nav-item>
              <b-nav-item href="https://salesforce-internal.slack.com/archives/C02RDS0USER" target="_blank"><img src="../assets/images/cyber/icon-slack.png" />#help-security</b-nav-item>
            </b-nav>
            <!-- <div class="footer-text-area">
              <p class="footer-text">
                <a href="tel:14155365230"></a><br>
                <a href="mailto:security@salesforce.com">security@salesforce.com</a><br>
                <a href="https://slack.com/archives/C02RDS0USER" target="_blank">#help-security</a>
              </p>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.footerNav {
  a {
    // color: rgba(51, 51, 51, 0.5);
    color: #303438;
    padding: 0 0 .8rem;
    text-decoration: underline;
    font-weight: 400;
    font-size: .9rem;

    &:hover {
      color: black;
      text-decoration: none;
    }

    img {
      max-width: 30px;
      padding-right: 10px;
    }
  }
}

.footer-top {
  padding: 75px 0;
  background: #ffffff;
}

.footer-text-area {
  display: flex;
  align-items: flex-start;
}

footer .footer-title {
  font-family: 'avantgarde';
  font-size: 0.9rem;
  // text-transform: uppercase;
  color: #032D60;
  // font-weight: bold;
}

footer p.footer-text {
  font-size: 15px;
  color: #1d315f;
  flex-shrink: 5;
}
</style>
