import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueFuse from "vue-fuse";
import Vuex from 'vuex'
import VueGtag from "vue-gtag";

import './assets/scss/styles.scss'

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)
Vue.use(VueFuse);
// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(VueGtag, {
  config: { id: "G-PQJZM2LPP6" }
});

Vue.use(Vuex)

Vue.config.productionTip = false

import store from './store'

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
