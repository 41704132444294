import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        translateJP: false
    },
    mutations: {
        toggleJP(state) {
            state.translateJP = !state.translateJP
        }
    }
})

window.store = store;
export default store;