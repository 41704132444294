import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import VueCookies from 'vue-cookies'

Vue.use(VueCookies)
Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  // {
  //   path: "/our-story",
  //   name: "Our Story",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/OurStory.vue"),
  // },
  { path: "*", component: Home },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

// console.log(Vue.$cookies.get('saml_auth'));
// console.log(Vue.$cookies.get('saml_auth') == 1);

// router.beforeEach((to, from, next) => {
//   if (Vue.$cookies.get('saml_auth') != 1 && to.name !== 'Home') next({ name: 'Home' })
//   else next()
// })

// router.beforeEach((to, from, next) => {
//   if (Vue.$cookies.get('saml_auth') != 1 && to.name !== 'Home') {
//     next({ name: 'Home' }) }
//   else if (Vue.$cookies.isKey('original_url')) {
//     var navigate_to = Vue.$cookies.get('original_url')
//     Vue.$cookies.remove('original_url')
//     next({ name: navigate_to })
//   }
//   else {
//     next()
//   }
// })

export default router
